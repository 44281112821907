<template>
    <div class="all">
        <div class="title">
            酒产品
        </div>
        <div class="list">
            <div class="item" v-for="item in imgArr" :key="item.id" @click="ToWineItem(item.id,item.url,item.textmsg)">
                    <img  class="imgset"   :src="item.url" alt="暂无图片">
                    <div class="msgbox"> 
                        <span>
                            {{item.textmsg}}
                        </span>
                    </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            imgArr:[
                {
                    id:0,
                    url:require("@/assets/WineProducts/WineProducts1.jpg"),
                    textmsg:"名士千邑百兰地"
                },
                {
                    id:1,
                    url:require("@/assets/WineProducts/WineProducts2.jpg"),
                    textmsg:"XO千邑白兰地"
                },
                {
                    id:2,
                    url:require("@/assets/WineProducts/WineProducts3.jpg"),
                    textmsg:"蓝带千邑白兰地"
                },
                {
                    id:3,
                    url:require("@/assets/WineProducts/WineProducts4.jpg"),
                    textmsg:"轩尼诗XO酒"
                },
            ]
        }
    },
    methods: {
        ToWineItem(id,url,textmsg) {
            this.$router.push({
            path:'/wineitem' ,
            query:{
                id,
                url,
                textmsg,
            }
            }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
                // 只有当错误不是 NavigationDuplicated 时才抛出
                throw err;
            }
            });
        },
    },
}
</script>

<style scoped>
.all{
    margin: 0 0 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.title{
    width: 1190px;
    height: 40px;
    color: #333;
    font-size: 30px;
    margin: 20px 0 30px;
}
.item{
    width: 1190px;
    display: flex;
    flex-direction: column;

}
.image-container{
    width: 290px;

}
.imgset{
    width: 290px;
    border: 0;
    margin: 0
}
.msgbox{
    font-size: 16px;
    background-color: #f8f8f8;
    width: 258px;
    height: 16px;
    padding: 16px;
}
.list{
    width: 1190px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    user-select: none;

}
.item:hover .msgbox {
    background-color: #e84846;
    color: white;
}
</style>