<template>
<div class="all">
    <div class="index">
        <div>您现在的位置：</div>
        <div class="indexsub" @click="ToHome">
            首页
        </div>
        <div class="next"> > </div>
        <div class="indexsub" @click="ToSkin()">
            护肤产品
        </div>
        <div class="next"> > </div>
        <div class="indexsub">
            {{textmsg}}
        </div>
    </div>
    <div class="main">
        <img class="mainimg" :src="url" alt="">
        <div class="right">
            <div class="title">{{ textmsg }}</div>
            <div class="price">
                价格面议
            </div>
            <!-- <div class="tips">
                <div class="tip">
                    美白
                </div>
                <div class="tip">
                    修复
                </div>   
            </div> -->
        </div>
    </div>
    <div class="menu">
        <div class="menuset">
            <div>
                产品详情
            </div>
        </div>
    </div>
    <div class="msg">
        <div class="msglist">
            <div v-for="msg in currentMainMsg" :key="msg" class="msgitem">
            {{ msg }}
            </div>
        </div>
    </div>
    <div class="listTitle">
        相关推荐
    </div>
    <div class="list">
        <div class="item"  v-for="item in filteredItems" :key="item.id" @click="ToSkinItem(item.id,item.url,item.textmsg)">
                <img  class="imgset"   :src="item.url" alt="暂无图片">
                <div class="msgbox"> 
                    <span>
                        {{item.textmsg}}
                    </span>
                </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name:'SkinItem',
    props:['id','url','textmsg'],
    data(){
        return{
            imgArr:[
                {
                    id:0,
                    url:require("@/assets/SkinCare/SkinCare1.jpg"),
                    textmsg:"POLA洁面",
                    mainmsg:['品牌：POLA/宝丽洁面','单品：POLA宝丽 黑BA晨光洗面奶100g', '产地：日本',
                            '洁面分类：洁面乳/膏','适合肤质：任何肤质','功效：控油 深层清洁 滋润',
                            '规格类型：正常规格','是否为特殊用途化妆品：否','净含量：100g',
                            '限期使用日期范围：2025-05-01至2026-04-30','化妆品保质期：36个月','保质期：3年'],
                },
                {
                    id:1,
                    url:require("@/assets/SkinCare/SkinCare2.jpg"),
                    textmsg:"赫莲娜绿宝瓶三件套",
                    mainmsg:['品牌：HR/赫莲娜','面部护理套装：黑绷带旅行套装','产地：法国',
                            '颜色分类：全明星修护礼盒 全明星抗老化礼盒', '化妆品备案编号/注册证号：国妆网备进字（沪）2022005434','适合肤质：多种肤质',
                            '功效：保湿 修护 紧致','规格类型：正常规格','是否为特殊用途化妆品：否',
                            '限期使用日期范围：2025-05-01至2025-12-01'],
                },
                {
                    id:2,
                    url:require("@/assets/SkinCare/SkinCare3.jpg"),
                    textmsg:"赫莲娜黑绷带",
                    mainmsg:[
                            '品牌：HR/赫莲娜',
                            '赫莲娜系列：活颜修护晚霜',
                            '产地：法国',
                            '系列：赫莲娜活颜修护晚霜',
                            '乳液/面霜品类：面霜',
                            '化妆品备案编号/注册证号：国妆网备进字（沪）2020007753',
                            '适合肤质：多种肤质',
                            '功效：修护',
                            '规格类型：正常规格',
                            '是否为特殊用途化妆品：否',
                            '净含量：50ml 100ml 15ml 50g 100g',
                            '限期使用日期范围：2025-01-01至2025-12-01',
                            '核心功效：保湿 修护 舒缓'
                            ],
                },
                {
                    id:3,
                    url:require("@/assets/SkinCare/SkinCare4.jpg"),
                    textmsg:"IPSA流金水",
                    mainmsg:[
                            '品牌：IPSA/茵芙莎',
                            '化妆水/爽肤水单品：流金岁月凝润美肤水',
                            '产地：日本',
                            '化妆品品类：其它化妆水/爽肤水',
                            '化妆品备案编号/注册证号：国妆特进字J20150585',
                            '上市时间：2015年',
                            '月份：7月',
                            '功效：补水 保湿',
                            '规格类型：正常规格',
                            '是否为特殊用途化妆品：是',
                            '净含量：200ml 200g',
                            '限期使用日期范围：2025-12-01至2026-06-01',
                            '质地：水液',
                            '保质期：5年',
                            '核心功效：祛斑美白'
                            ],
                },
            ]
        }
    },
    computed: {
        // 过滤出id不等于当前id的图片
        filteredItems() {
            const currentId = Number(this.id); // 转换为数字类型
            return this.imgArr.filter(item => item.id !== currentId);
        },
        currentMainMsg() {
        const currentId = Number(this.id); // 确保 id 是数字类型
        const currentItem = this.imgArr.find(item => item.id === currentId);
        return currentItem ? currentItem.mainmsg : [];
        }
    },
    created(){
        this.scrollToTop(); // 调用滚动到顶部的方法
    },
    methods:{
        ToHome() {
            this.$router.push('/').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
        ToSkin() {
            this.$router.push('/skin').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        ToSkinItem(id,url,textmsg) {
            this.$router.push({
            path:'/skinitem' ,
            query:{
                id,
                url,
                textmsg,
            }
            }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
                // 只有当错误不是 NavigationDuplicated 时才抛出
                throw err;
            }
            });       
            this.scrollToTop(); // 调用滚动到顶部的方法
        },
    }
}
</script>

<style scoped>
.all{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.index{
    width: 1190px;
    color: #333;
    display: flex;
    margin: 0 0 30px 0;
}
.indexsub{
    margin: 0 4px;
    cursor: pointer;
}
.indexsub:hover{
    color: #e84846;
}
.next{
    margin: 0 4px;
}
.main{
    margin: 0 0 50px;
    display: flex;
}
.mainimg{
    width: 419.4px;
    height: 313.45px;
    border: 1px solid #eee;
    user-select: none;
}
.right{
    width: 727px;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 40px ;
}
.title{
    color: #333;
    font-size: 30px;
    font-weight: 900;
}
.price{
    margin: 13px 0 10px 0 ;
    padding: 30px 20px 25px;
    background-color: #f5f5f5;
    font-size: 26px;
    color: #e6322e;
    font-weight: 900;
}
.tips{
    display: flex;
}
.tip{
    color: #e84846;
    background-color: #fff4f2;
    padding: 0 6px;
    margin: 0 6.75px 6.75px 0;
    font-size: 12px;
}
.menu{
    background-color: #f5f5f5;
    width: 1190px;
    height: 60px;
}
.menuset{
    width: 80.8px;
    height: 60px;
    font-size: 20px;
    color: #e84846;
    padding: 0 50px;
    background-color: #fff;
    border-top: 3px solid #e84846;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.msg{
    padding: 40px;
    width: 1110px;
}
.msglist{
    display: flex;
    flex-wrap: wrap;
}
.msgitem{
    width: 300px;
    height: 30px;
    margin: 0 20px;
    color: #555;    
    white-space: nowrap; /* 保持文本在一行内 */
    overflow: hidden; /* 隐藏超出容器的文本 */
    text-overflow: ellipsis; /* 在文本溢出时显示省略号 */
    
}

.listTitle{
    padding: 56.25px 0;
    font-size: 30px;
    color: #000;
    width: 1190px;
}
.imgset{
    width: 390px;
    border: 0;
    margin: 0
}
.msgbox{
    font-size: 16px;
    background-color: #f8f8f8;
    width: 356px;
    height: 60px;
    color: #333;
    display: flex;
    align-items: center;    
    padding-left: 20px  ;
    user-select:none;
}
.msgbox:hover{
    color: #fff;
    background-color: #e84846;
}
.item{
    width: 1190px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.list{
    width: 1190px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    user-select: none;

}
</style>