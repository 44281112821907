<template>
    <div class="all">
        <div class="block">
            <el-carousel  :interval=3000 trigger="click" height="600px">
            <el-carousel-item v-for="item in imgArr" :key="item.id">
                <img  :src="item.url" class="img" alt="暂无图片">
            </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
export default {
    name:'SlideShow',
    data(){
        return{
            imgArr:[
                {
                    id:0,
                    url:require("@/assets/SlideShow/SlideShow1.png")
                },
                {
                    id:1,
                    url:require("@/assets/SlideShow/SlideShow2.png")
                }
            ]
        }
    }
	}

</script>

<style scoped>
.all {
    display: flex;
    justify-content: center;
}
.block{
    width: 1420px;
    /* margin: 0 auto;
     */
}
</style>