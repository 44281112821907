import { render, staticRenderFns } from "./HomeFoot.vue?vue&type=template&id=397a9f12&scoped=true"
import script from "./HomeFoot.vue?vue&type=script&lang=js"
export * from "./HomeFoot.vue?vue&type=script&lang=js"
import style0 from "./HomeFoot.vue?vue&type=style&index=0&id=397a9f12&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "397a9f12",
  null
  
)

export default component.exports