<template>
    <div class="all">
        <div class="title">
                公司介绍
        </div>
        <div class="main">
            <div class="textset">
                <div class="text">
                    IDEAL FUTURE LIMITED是一家集全球优质大牌美妆、日本威士忌、清酒、干邑/白兰地、苏格兰威士忌等品牌货源供应链的综合业务企业集团，为国内主流直播与电商平台、经销代理商、零售商等提供一站式供应链服务及解决方案。

                </div>
                <!-- <div class="text">
                    面部产品供应：洁面系列、面膜系列、爽肤水系列、精华系列、乳液系列、膏霜系列、精油系列、原液系列等。
                </div>
                <div class="text">
                    三大营销培训战略：1、全新的院线拓店，拓客模式，低价一站式获得全部美容院所需产品；2、美容院和互联网+的结合，全新的院线订制预约消费模式；3、向顾客提供来自全球各地的院装产品和先进技术相结合。
                </div> -->
            </div>
                <img  class="img" src="@/assets/About/About1.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name:'AboutPage',
}
</script>

<style scoped>
.all{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 30px;
}
.title{
    color: #333;
    font-size: 30px;
    height: 40px;
    width: 1190px;
    margin: 0 0 30px;
}
.main{
    width: 1190px;
    height: 360px;
    display: flex;
    justify-content: space-between;
}
.text{
    color: #333;
    margin: 0 0 20px;
    line-height: 2;
    word-wrap: break-word;
}
.img{
    width: 590px;
    height: 332px;
    margin-left: 40px;
}
</style>