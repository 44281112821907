<template>
  <div id="app">
    <div class="top">
    </div>
    <div>
      <WebHeader></WebHeader>
    </div>
    <div>
      <router-view></router-view>
    </div>
    <div>
      <WebFoot></WebFoot>
    </div>
    <!-- <div>
            <PopView v-show="popflag"></PopView>
    </div> -->
    <div>
      <!-- 右边的固定浮窗 -->
      <div class="right">
        <!-- <div>
          <el-button class="icon">
            <div class="img">
              <img class="size icon1" src="./assets/Icon/电 话.png" alt="">
              <img class="size icon2" src="./assets/Icon/电 话2.png" alt="" >
            </div>
            <div class="text" @click="changflag">
              免费回电
            </div>
          </el-button>    
        </div>
        <div>
          <el-button class="icon">
            <div class="img">
              <img class="size icon1" src="./assets/Icon/消息.png" alt="">
              <img class="size icon2" src="./assets/Icon/消息2.png" alt="">
            </div>
            <div class="text">
              免费咨询
            </div>
          </el-button>
        </div> -->
        <div>
          <a href="javascript:scrollTo(0,0)"><!-- 返回顶部 -->
            <el-button class="icon"  >
              <div>
                <img class="size icon1" src="./assets/Icon/火箭.png" alt="">
                <img class="size icon2" src="./assets/Icon/火箭2.png" alt="">
              </div>
            </el-button>
          </a>
        </div>
      </div>
    </div>
    <div>
    <!-- 底部的固定浮窗 -->
      <!-- <div class="foot">
        <div class="footleft">
          <div class="bigmsg">
            免费咨询专业解答
          </div>
          <div class="smallmsg">
            免费电话咨询请放心接听
          </div>
        </div>
        <div class="footright">
          <el-button class="button">
            <div class="set">
              <div>
                <img class="buttonimg" src="./assets/Icon/电 话2.png" alt="" >
              </div>
              <div  class="bottontext" @click="changflag">
                免费回电
              </div>
            </div>
          </el-button>
        </div>
      </div> -->

    </div>
  </div>
</template>

<script>

// import PageHome from './components/PageHome';
import WebFoot from './components/WebFoot'
import WebHeader from './components/WebHeader'
// import PopView from './components/PopView.vue';
export default{
  data(){
        return{
            // popflag:false
        }
    },
  methods:{
    // changflag(){
    //   this.popflag = false
    // },
      
  },
  components: {
    WebFoot,
    WebHeader,
    // PopView
  },
}
</script>
<style scoped>
.top{
  height: 82px;
}
.right{
  width: 60px;
  height: 60px;
  position: fixed; 
  bottom:180px;
  right: 20px;
  background-color: #fff;
  z-index: 1000;
}
/* .foot{
  height: 60px;
  width: 324.25px;
  background-color: #fff;
  position: fixed; 
  bottom:0;
  right: 10px;
  display: flex;
  z-index: 1000;
} */
.icon{ 
  width: 60px;
  height: 60px;
  padding: 5px;
  border: 0px;
}
/* .footleft{
  width: 172px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footright{
  width: 173.25px;
  height: 60px;
} */
.size{
  width: 20px;
  height: 20px;
}
.text{
  width: 50px;
  height: 12px;
  font-size: 12px;
  color: #666;
}
.img{
  width: 50px;
  height: 20px;
  padding: 0 0 4px;
}
.icon:hover {
  background-color: red; /* 按钮背景变为红色 */
}

.icon:hover .text {
  color: white; /* 文本变为白色 */
}
.icon2{
  display: none; /*第二个图标不显示*/
}
.icon:hover .icon1 {
  display: none; /* 鼠标来到时隐藏第一个图标 */
}

.icon:hover .icon2 {
  display:inline-block; /* 鼠标来到时显示第二个图标 */
}
/* .bigmsg{
  width: 132px;
  font-size: 16px;
  color: #333;
}
.smallmsg{
  width: 132px;
  font-size: 12px;
  color: #999;
}
.button{
  width: 173.5px;
  height: 60px;
  background-color: #e84846;
  padding: 20px 40px;
}
.buttonimg{
  width: 18px;
  height: 18.4px;
  padding-right: 15px;
}
.bottontext{
  font-size: 16px;
  color: #fff;
}
.set{
  display: flex;
} */
</style>
