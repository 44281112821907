<template>
    <div class="HomeFoot">
        <div class="main">
            <div class="msgandmap">
                <div class="msg">
                    <div class="msg1">
                        联系我们
                    </div>
                    <div class="msg2">
                        <div class="msg21">
                            公司地址：
                        </div>
                        <div class="msg22">
                            香港新界上水彩圆道9号深港中心 109-111 室
                        </div>
                    </div>
                    <div class="msg3">
                        联系电话：18823767240
                    </div>
                </div>
                <!-- <div class="map">

                </div> -->

            </div>

        </div>
        <div class="img">
            <img class="img" src="@/assets/HomeFoot/HomeFoot2.jpg" alt="">
        </div>

    </div>
</template>

<script>
export default {
    name: 'HomeFoot',
    data() {
        return {
        }
    }
}
</script>

<style scoped>
.HomeFoot {
    flex-direction: column;
    display: flex;
    align-items: center;
}

.main {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    width: 1420px;
    height: 364px;
    background-image: url("@/assets/HomeFoot/HomeFoot1.jpg");
}

.msgandmap {
    width: 1190px;
    height: 364px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .map{
    margin:  0 0 0 50px;
    width: 640px;
    height: 254px;
    background-color: #888888;
} */
.img {
    width: 1420px;
    height: 94px;
    padding: 15px 0 0 0;
    color: #ffffff;
}

.msg {

    color: #ffffff;
    width: 525px;
    height: 136px;
}

.msg1 {
    font-size: 30px;
    margin: 0 0 20px;
}

.msg2 {
    display: flex;
    font-size: 16px;
    align-items: center;
}

.msg3 {
    font-size: 16px;
    margin: 20px 0 0;
}

.msg21 {
    white-space: nowrap;
    width: 80px;
    margin: 0 5px 0 0;
}

.msg22 {
    margin: -4px 0;
}</style>