<template>
    <div class="all">
        <div class="card">
            <!-- <div class="input">
                <div class="text">
                    在线咨询 获取优惠报价
                </div>
                <div class="inputdiv">
                    <div class="inputbox">
                        <div class="enter">
                            <input 
                                type="text"
                                :class="inputClass1" 
                                @focus="changeInputClass1(true)"  
                                @blur="changeInputClass1(false)" 
                                @compositionstart="handleCompositionStart1"
                                @compositionend="handleCompositionEnd1"
                                @input="handleInput1"
                                v-model="input1" 
                            >
                            <div class="tips" v-show="!isComposing1 && nameflag && !input1.trim()">请输入您的称呼<div class="require">(必填)</div></div>
                            <div class="error" v-show="errorflag1">
                                请输入称呼
                            </div>
                            <div class="error" v-show="errorflag2">
                                仅支持汉字字母，长度2～8个字符
                            </div>
                        </div>
                        <div class="enter">
                            <input
                                type="text"
                                :class="inputClass2" 
                                @focus="() => { handleFocus2(); changeInputClass2(true); }"  
                                @blur="() => { handleBlur2(); changeInputClass2(false); }"  
                                @compositionstart="handleCompositionStart2"
                                @compositionend="handleCompositionEnd2"
                                @input="handleInput2"
                                v-model="input2"
                                
                            >
                            <div class="tips" v-show="!isComposing2 && phoneflag && !input2.trim()">请输入手机号<div class="require">(必填)</div></div>
                            <div class="count" v-show="showCount">{{ input2.length }}/11</div>
                            <div class="error" v-show="errorflag3">
                                请输入电话
                            </div>
                            <div class="error" v-show="errorflag4">
                                请输入正确的手机号格式
                            </div>  
                        </div> 
                    </div>
                </div>
                <div class="buttondiv">
                    <div>
                        <el-button  class="button" type="danger">在线咨询</el-button>
                    </div>

                </div>
            </div> -->
        </div>
        <div>
            <img class="img" src="@/assets/OnlineConsult/OnlineConsult2.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name:'ContactCard',
    data(){
        return{
            input1: '',
            input2: '',
            inputClass1:'inputset1',
            inputClass2:'inputset2',
            nameflag: true,
            phoneflag: true,
            errorflag1:false,
            errorflag2:false,
            errorflag3:false,
            errorflag4:false,
            showCount: false,
            isComposing1: false,// 输入法状态
            isComposing2: false,
        }
    },
    methods: {
        // changeInputClass1(focused) {
        //     if (focused) {
        //         this.inputClass1 = 'inputset1';
        //         this.nameflag = true;
        //         this.resetErrorFlags1();
        //     } else {
        //         this.validateInput1();
        //         // 校验后再决定是否更改类名
        //         if (this.errorflag1 || this.errorflag2) {
        //             this.inputClass1 = 'oninputset1';
        //         }
        //     }
        // },
        // changeInputClass2(focused) {
        //     if (focused) {
        //         this.inputClass2 = 'inputset2';
        //         this.phoneflag = true;
        //         this.resetErrorFlags2();
        //     } else {
        //         this.validateInput2();
        //         // 校验后再决定是否更改类名
        //         if (this.errorflag3 || this.errorflag4) {
        //             this.inputClass2 = 'oninputset2';
        //         }
        //     }
        // },
        // validateInput1() {
        //     this.errorflag1 = !this.input1.trim();
        //     const regex = /^[a-zA-Z\u4e00-\u9fa5]{2,8}$/;
        //     this.errorflag2 = !regex.test(this.input1) && this.input1.trim();
        // },
        // validateInput2() {
        //     this.errorflag3 = !this.input2.trim();
        //     this.errorflag4 = !(this.input2.trim().length === 11) && this.input2.trim();
        // },
        // resetErrorFlags1() {
        //     this.errorflag1 = false;
        //     this.errorflag2 = false;
        // },
        // resetErrorFlags2() {
        //     this.errorflag3 = false;
        //     this.errorflag4 = false;
        // },

        // handleInput2() {
        //     this.input2 = this.input2.replace(/[^0-9]/g, '');
        //     if (this.input2.length > 11) {
        //         this.input2 = this.input2.substring(0, 11);
        //     }
        //     this.phoneflag = !this.input2.trim();
        // },
        // handleInput1() {
        // this.nameflag = !this.input1.trim();      
        // },
        // handleFocus2() {
        //     console.log("Focused on input2");
        //     this.showCount = true;
        // },
        // handleBlur2() {
        //     console.log("Blurred from input2");
        //     this.showCount = false;
        // },
        // handleCompositionStart1() {
        //     this.isComposing1 = true;
        // },
        // handleCompositionEnd1() {
        //     this.isComposing1 = false;
        //     this.validateInput1();
        // },
        // handleCompositionStart2() {
        //     this.isComposing2 = true;
        // },
        // handleCompositionEnd2() {
        //     this.isComposing2 = false;
        //     this.validateInput2();
        // },
    }
}
</script>

<style scoped>
.all{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.card{
    display: flex;
    justify-content:flex-end;
    align-items: center;
    width: 1380px;
    height: 514px;
    padding: 0 40px 0 0 ;
    background-image: url("@/assets/OnlineConsult/OnlineConsult.jpg");
}
.img{
    width: 1420px;
    height: 800px;
}
/*.input{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    height: 356.4px;
    background-color: #FFFFFF;
    padding: 20px 0;
    border-radius: 10px;

}
.inputdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    height: 224px;
}
.inputbox{
    width: 350px;
    height: 148px;
    margin: 38px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.text{
    font-size: 23px;
    color: #333333;
    padding: 10px 0;
}
.enter{
    width: 350px;
    height: 60px;
    position:relative
}
.tips{
    display: flex;
    position:absolute;
    top: 20px;
    left: 20px;
    color: #c4c4c4;
    font-size: 16px;
    pointer-events: none;
}
.require{
    width: 64px;
    color: orange;
    text-align: center;
    
}
.inputset1{
    font-size: 16px;
    color: #c4c4c4;
    width: 310px; 
    height: 60px; 
    background-color: #f5f5f5;
    padding: 0 20px;
    border: none
}
.oninputset1{
    font-size: 16px;
    color: #c4c4c4;
    width: 310px; 
    height: 60px; 
    background-color: #fef3f1;
    padding: 0 20px;
    border: none;
}
.inputset2{
    font-size: 16px;
    color: #c4c4c4;
    width: 310px; 
    height: 60px; 
    background-color: #f5f5f5;
    padding: 0 20px;
    border: none
}
.oninputset2{
    font-size: 16px;
    color: #c4c4c4;
    width: 310px; 
    height: 60px; 
    background-color: #fef3f1;
    padding: 0 20px;
    border: none;
}
input:focus {
    outline: none;
}
.button{
    width: 350px;
    height: 60px;
    font-size: 20px;
    background-color: #E84846;
    border-radius: 2px;
}
.buttondiv{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.error{
    position:absolute;
    margin: 14px 0 0 ;
    padding: 10px 20px;
    background-color: #fff;
    color: #ff1212;
    font-size: 16px;
    border: 1px solid #d8d8d8;
    border-radius:4px;
    line-height:1.5;
    z-index: 1000;
    position: relative;
    display: inline-block; 
}

.error::before {
    content: '';
    position: absolute;
    left: 50%;
    top: -11px; 
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 11px solid transparent; 
    border-right: 11px solid transparent; 
    border-bottom: 11px solid #d8d8d8; 
}

.error::after {
    content: '';
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent; 
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
}
.count {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #333; 
}*/


</style>