<template>
    <div class="home">
        <div>
            <SlideShow></SlideShow>
        </div>
        <div>
            <PageSkin></PageSkin>
        </div>
        <div>
            <PageWine></PageWine>
        </div>
        <div>
            <ContactCard></ContactCard>
        </div>
        <!-- <div>
            <PageDisplay></PageDisplay>
        </div> -->
        <div>
            <WareHouse></WareHouse>
        </div>
        <div>
            <PageAbout></PageAbout>
        </div>
        <div>
            <HomeFoot></HomeFoot>
        </div>

    </div>
</template>

<script>
import ContactCard from './Homecomponents/ContactCard.vue';
import HomeFoot from './Homecomponents/HomeFoot';
import PageAbout from './Homecomponents/PageAbout.vue';
// import PageDisplay from './Homecomponents/PageDisplay.vue';
import PageSkin from './Homecomponents/PageSkin';
import SlideShow from './Homecomponents/SlideShow';
import PageWine from './Homecomponents/PageWine';
import WareHouse from './Homecomponents/WareHouse.vue';


export default {
    name:'PageHome',

    components: {
    SlideShow,
    HomeFoot,
    PageSkin,
    ContactCard,
    PageAbout,
    // PageDisplay,
    PageWine,
    WareHouse,

}
}
</script>,

<style scoped>

</style>