import Vue from 'vue'
import VueRouter from 'vue-router'
import PageHome from '../components/PageHome.vue'
import SkinCare from '../components/SkinCare.vue'
// import StoreDisplay from '../components/StoreDisplay.vue'
import AboutPage from '../components/AboutPage.vue'
import ContactPage from '../components/ContactPage.vue'
// import StoreItem from '../components/StoreItem.vue'
import SkinItem from '../components/SkinItem.vue'
import WineItem from '../components/WineItem.vue'
import WineProducts from '../components/WineProducts.vue'




Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: PageHome,
        meta:{title:'首页'},
    },
    {
        path: '/about',
        name: 'about',
        component: AboutPage,
        meta:{title:'关于我们'},

    },
    {
        path: '/skin',
        name: 'skin',
        component: SkinCare,
        meta:{title:'护肤产品'},
    },    
    {
        path: '/wine',
        name: 'wine',
        component: WineProducts,
        meta:{title:'酒产品'},
    }, 
    // {
    //     path: '/store',
    //     name: 'store',
    //     component: StoreDisplay
    // },
    {
        path: '/contact',
        name: 'contact',
        component: ContactPage,
        meta:{title:'联系我们'},
    },
    // {
    //     path: '/storeitem',
    //     name: 'storeitem',
    //     component: StoreItem,
    //     props({query}){
    //         return{
    //             id:query.id,
    //             url:query.url,
    //         }
    //     }
    // },
    {
        path: '/skinitem',
        name: 'skinitem',
        component: SkinItem,
        meta:{title:'护肤产品详情'},
        props({query}){
            return{
                id:query.id,
                url:query.url,
                textmsg:query.textmsg,
            }
        }
    },
    {
        path: '/wineitem',
        name: 'wineitem',
        component: WineItem,
        meta:{title:'酒产品详情'},
        props({query}){
            return{
                id:query.id,
                url:query.url,
                textmsg:query.textmsg,
            }
        }
    },
    ]


const router = new VueRouter({
    routes
})
router.afterEach((to) =>{
    document.title = to.query.textmsg || to.meta.title
    console.log(to.query);
})

export default router