<template>
    <div class="all">
        <div class="index">
            <div>您现在的位置：</div>
            <div class="indexsub" @click="ToHome">
                首页
            </div>
            <div class="next"> > </div>
            <div class="indexsub" @click="ToWine()">
                酒产品
            </div>
            <div class="next"> > </div>
            <div class="indexsub">
                {{textmsg}}
            </div>
        </div>
        <div class="main">
            <img class="mainimg" :src="url" alt="">
            <div class="right">
                <div class="title">{{ textmsg }}</div>
                <div class="price">
                    价格面议
                </div>
                <!-- <div class="tips">
                    <div class="tip">
                        美白
                    </div>
                    <div class="tip">
                        修复
                    </div>   
                </div> -->
            </div>
        </div>
        <div class="menu">
            <div class="menuset">
                <div>
                    产品详情
                </div>
            </div>
        </div>
        <div class="msg">
            <div class="msglist">
                <div v-for="msg in currentMainMsg" :key="msg" class="msgitem">
                {{ msg }}
                </div>
            </div>
        </div>
        <div class="listTitle">
            相关推荐
        </div>
        <div class="list">
            <div class="item"  v-for="item in filteredItems" :key="item.id" @click="ToWineItem(item.id,item.url,item.textmsg)">
                    <img  class="imgset"   :src="item.url" alt="暂无图片">
                    <div class="msgbox"> 
                        <span>
                            {{item.textmsg}}
                        </span>
                    </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    export default {
        name:'SkinItem',
        props:['id','url','textmsg'],
        data(){
            return{
                imgArr:[
                {
                    id:0,
                    url:require("@/assets/WineProducts/WineProducts1.jpg"),
                    textmsg:"名士千邑百兰地",
                    mainmsg:[
                            '品牌：Martell/马爹利',
                            '品名：蓝带白兰地',
                            '产地：法国',
                            '净含量：1000mL',
                            '包装种类：礼盒装',
                            '颜色分类：1000ml',
                            '是否为有机食品：否',
                            '酒精纯度：中度酒（38%≤A≤50%）',
                            '体积(ml)：1000',
                            '包装方式：包装',
                            '级别：无等级',
                            '适用场景：商务宴请区',
                            '度数：40%Vol.',
                            '包装规格：1瓶',
                            '储存条件：常温',
                            '单瓶净含量：1000ml',
                            '厂名：Martell & Co.',
                            '厂址：法国',
                            '厂家联系方式：详情见背标',
                            '保质期：3650天'
],
                },
                {
                    id:1,
                    url:require("@/assets/WineProducts/WineProducts2.jpg"),
                    textmsg:"XO千邑白兰地",
                    mainmsg:[
                            '厂名: 见瓶身',
                            '厂址: 见瓶身',
                            '配料表: 见瓶身',
                            '储藏方法: 常温',
                            '保质期: 9999',
                            '食品添加剂: 见瓶身',
                            '品牌: Martell/马爹利',
                            '品名: 名士干邑白兰地',
                            '产地: 法国',
                            '包装种类: 礼盒装',
                            '颜色分类: 马爹利名士老款700ml',
                            '酒精纯度: 40%vol',
                            '包装方式: 包装',
                            '级别: V.S.O.P'
                            ],
                },
                {
                    id:2,
                    url:require("@/assets/WineProducts/WineProducts3.jpg"),
                    textmsg:"蓝带千邑白兰地",
                    mainmsg:[
                            '品牌：Martell/马爹利',
                            '品名：马爹利XO 1L',
                            '产地：法国',
                            '包装种类：瓶装',
                            '包装方式：包装',
                            '厂名：Martell'
                            ],
                },
                {
                    id:3,
                    url:require("@/assets/WineProducts/WineProducts4.jpg"),
                    textmsg:"轩尼诗XO酒",
                    mainmsg:[
                            '品牌：Hennessy/轩尼诗',
                            '品名：轩尼诗XO干邑白兰地',
                            '产地：法国',
                            '净含量：700mL',
                            '包装种类：瓶装',
                            '颜色分类：700ml单支',
                            '酒精纯度：中度酒（38%≤A≤50%）',
                            '包装方式：包装',
                            '度数：40vol',
                            '厂名：法国轩尼诗公司',
                            '厂址：法国轩尼诗公司'
                            ],
                },
            ]
            }
        },
        computed: {
            // 过滤出id不等于当前id的图片
            filteredItems() {
                const currentId = Number(this.id); // 转换为数字类型
                return this.imgArr.filter(item => item.id !== currentId);
            },
            currentMainMsg() {
            const currentId = Number(this.id); // 确保 id 是数字类型
            const currentItem = this.imgArr.find(item => item.id === currentId);
            return currentItem ? currentItem.mainmsg : [];
            }
        },
        created(){
            this.scrollToTop(); // 调用滚动到顶部的方法
        },
        methods:{
            ToHome() {
                this.$router.push('/').catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        // 只有当错误不是 NavigationDuplicated 时才抛出
                        throw err;
                    }
                });
            },
            ToWine() {
                this.$router.push('/wine').catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        // 只有当错误不是 NavigationDuplicated 时才抛出
                        throw err;
                    }
                });
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            },
            ToWineItem(id,url,textmsg) {
                this.$router.push({
                path:'/wineitem' ,
                query:{
                    id,
                    url,
                    textmsg,
                }
                }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
                });       
                this.scrollToTop(); // 调用滚动到顶部的方法
            },
        }
    }
    </script>
    
    <style scoped>
    .all{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .index{
        width: 1190px;
        color: #333;
        display: flex;
        margin: 0 0 30px 0;
    }
    .indexsub{
        margin: 0 4px;
        cursor: pointer;
    }
    .indexsub:hover{
        color: #e84846;
    }
    .next{
        margin: 0 4px;
    }
    .main{
        margin: 0 0 50px;
        display: flex;
    }
    .mainimg{
        width: 419.4px;
        height: 313.45px;
        border: 1px solid #eee;
        user-select: none;
    }
    .right{
        width: 727px;
        display: flex;
        flex-direction: column;
        margin: 0 0 0 40px ;
    }
    .title{
        color: #333;
        font-size: 30px;
        font-weight: 900;
    }
    .price{
        margin: 13px 0 10px 0 ;
        padding: 30px 20px 25px;
        background-color: #f5f5f5;
        font-size: 26px;
        color: #e6322e;
        font-weight: 900;
    }
    .tips{
        display: flex;
    }
    .tip{
        color: #e84846;
        background-color: #fff4f2;
        padding: 0 6px;
        margin: 0 6.75px 6.75px 0;
        font-size: 12px;
    }
    .menu{
        background-color: #f5f5f5;
        width: 1190px;
        height: 60px;
    }
    .menuset{
        width: 80.8px;
        height: 60px;
        font-size: 20px;
        color: #e84846;
        padding: 0 50px;
        background-color: #fff;
        border-top: 3px solid #e84846;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .msg{
        padding: 40px;
        width: 1110px;
    }
    .msglist{
        display: flex;
        flex-wrap: wrap;
    }
    .msgitem{
        width: 300px;
        height: 30px;
        margin: 0 20px;
        color: #555;    
        white-space: nowrap; /* 保持文本在一行内 */
        overflow: hidden; /* 隐藏超出容器的文本 */
        text-overflow: ellipsis; /* 在文本溢出时显示省略号 */
    }

    .listTitle{
        padding: 56.25px 0;
        font-size: 30px;
        color: #000;
        width: 1190px;
    }
    .imgset{
        width: 390px;
        border: 0;
        margin: 0
    }
    .msgbox{
        font-size: 16px;
        background-color: #f8f8f8;
        width: 356px;
        height: 60px;
        color: #333;
        display: flex;
        align-items: center;    
        padding-left: 20px  ;
        user-select:none;
    }
    .msgbox:hover{
        color: #fff;
        background-color: #e84846;
    }
    .item{
        width: 1190px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .list{
        width: 1190px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        user-select: none;
    
    }
    </style>