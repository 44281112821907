<template>
    <div class="all">
        <div class="list">
            <div class="msg" @click="ToHome">
                首页
            </div>
            <div class="msg" @click="ToSkin">
                护肤产品
            </div>
            <div class="msg" @click="ToWine">
                酒产品
            </div>
            <div class="msg" @click="ToAbout">
                关于我们
            </div>
            <div class="msg" @click="ToContact">
                联系我们
            </div>
        </div>
        <div class="endmsg">
            <div>
                Copyright©IDEAL FUTURE
            </div>
            <div>
                门店地址：香港新界上水彩圆道9号深港中心 109-111 室
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'WebFoot',
    methods: {
        ToHome() {
            this.$router.push('/').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
        ToSkin() {
            this.$router.push('/skin').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
        ToWine() {
            this.$router.push('/wine').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
        ToAbout() {
            this.$router.push('/about').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
        ToContact() {
            this.$router.push('/contact').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
    },
}
</script>

<style scoped>
.all {
    height: 75.6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f8f8f8;
    padding: 50px 0px 20px;
}

.endmsg {
    font-size: 12px;
    display: flex;
    justify-content: center;
    margin: 0 0 0 7.5px;
}

.list {
    display: flex;
    justify-content: center;
}

.msg {
    padding: 0px 50px;
    border-right: 1px solid #DCDCDC;
    user-select: none;
}

.msg:hover {
    color: #e84846;
    cursor: pointer;
}

.msg:last-child {
    border-right: none;
    /* 移除最后一个 .msg 元素的右边框 */
}
</style>