<template>
    <div class="all">
        <div class="text">
            仓库展示
        </div>
        <div class="text2">
            Warehouse display
        </div>
        <img class="img" src="@/assets/WareHouse/WareHouse.jpg" alt="">
    </div>
</template>

<script>
export default {
    name:'WareHouse',
}
</script>

<style scoped>
.all{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 0 50px;
}
.img{
    width: 1420px;
    padding: 20px;
}
.text{
    /* margin: 30px 0 0; */
    font-size: 30px;
    white-space: 1420px;
}
.text2{
    margin: 15px 0 30px;
    color: #999999;
}
</style>