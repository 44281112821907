import { render, staticRenderFns } from "./WareHouse.vue?vue&type=template&id=635d24ef&scoped=true"
import script from "./WareHouse.vue?vue&type=script&lang=js"
export * from "./WareHouse.vue?vue&type=script&lang=js"
import style0 from "./WareHouse.vue?vue&type=style&index=0&id=635d24ef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635d24ef",
  null
  
)

export default component.exports