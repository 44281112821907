<template>
    <div class="about">
        <div class="aboutset">
            <div class="msg">
                <div>
                    <div class="title">
                        关于我们
                    </div>
                    <div class="text">
                        <p>
                            IDEAL FUTURE LIMITED是一家集全球优质大牌美妆、日本威士忌、清酒、干邑/白兰地、苏格兰威士忌等品牌货源供应链的综合业务企业集团，为国内主流直播与电商平台、经销代理商、零售商等提供一站式供应链服务及解决方案。
                        </p>
                    </div>
                </div>
                <div >
                    <el-button class="button" @click="ToAbout">查看更多</el-button>
                </div>
            </div>
            <div>
                <img class="img" src="@/assets/About/About1.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'PageAbout',
    methods: {
        ToAbout() {
            this.$router.push('/about').catch(err => {
            if (err.name !== 'NavigationDuplicated') {
                // 只有当错误不是 NavigationDuplicated 时才抛出
                throw err;
            }
            });
        },
    },
}
</script>

<style scoped>
.about{
    display: flex;
    justify-content: center;
}
.aboutset{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 444px;
    width: 1420px;
    margin: 0 0 30px;
    background-color: #f8f8f8;
    border: 0;
}
.msg{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 340px;
    height: 292px;
    padding: 76px 20px 76px 40px;
}
.img{
    width: 790px;
    height: 440px;
}
.title{
    font-size: 30px;
    color: #333;
    margin: 0 0 20px;
}
.text{
    width: 338px;
    height: 160px;
    color: #333;

}
.button{
    background-color: #E84846;
    width: 140px;
    height: 40px;
    color: #FFFFFF;
    font-size: 16px;
    padding: 9px 30px;
    border-radius: 2px;
}
p{ 
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	overflow: hidden;
    line-height:32px;
}
p, i {
    padding: 0;
    margin: 0;
}
</style>