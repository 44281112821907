<template>
    <div class="all">
        <div class="Header">
            <div class="Headertitle">
                <div class="msg">
                    IDEAL FUTURE LIMITED
                </div>
            </div>
            <div class="right">
                <div class="change-area">
                    <div class="list-select">
                        <!-- <div class="select" v-show="selectflag">
                            <div class="borderchange" :class="{ focused: isInputFocused }">
                                <div class="selectmsg">
                                    产品
                                </div>
                                <div>
                                    <input class="input" type="text" placeholder="请输入关键词" @focus="isInputFocused = true" @blur="isInputFocused = false">
                                </div>
                                <div class="select-button">
                                    <el-button class="buttonset">
                                        <img class="selectimg" src="../assets/WebHeader/查找2.png" alt="">
                                    </el-button>
                                </div>
                            </div>
                        </div> -->
                        <div class="list" v-show="listflag">
                            <div class="test" @click="ToHome">
                                首页
                            </div>
                            <div class="test" @click="ToSkin">
                                护肤产品
                            </div>
                            <div class="test" @click="ToWine">
                                酒产品
                            </div>
                            <div class="test" @click="ToAbout">
                                关于我们
                            </div>
                            <div class="test" @click="ToContact">
                                联系我们
                            </div>
                        </div>
                        <!-- <div> 
                            <img class="quaryimg" src="../assets/WebHeader/查找.png" v-show="quaryflag" 
                            alt="">
                        </div>  -->
                        <!-- <div> 
                            <img class="closeimg" src="../assets/WebHeader/错.png" v-show="closeflag" @click="changeflag()" alt="">
                        </div>                                               -->
                    </div>
                </div>
                <div>
                    <div>
                        <!-- <el-button class="button">
                            <div class="button-center">
                                <div>
                                    <img class="phone" width="18px" height="18.4px" src="../assets/Icon/电 话2.png" alt="">
                                </div>
                                <div>
                                    免费回电    
                                </div>
                            </div>
                        </el-button> -->
                    </div>
                </div>

            </div>
        </div>


    </div>
</template> 

<script>
export default {
    name: 'WebHeader',
    data() {
        return {
            listflag:true,
            // selectflag:false,
            // quaryflag:true,
            // closeflag:false,
            // isInputFocused: false,
            // selectedItem: '产品',
        }
    },
    methods: {
        // changeflag(){
        //     this.closeflag=!this.closeflag
        //     this.selectflag=!this.selectflag     
        //     this.quaryflag=!this.quaryflag
        //     this.listflag=!this.listflag          
        // },
        // changeselectedItem(item){
        //     this.selectedItem=item
        // },
        ToHome() {
            this.$router.push('/').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
        ToSkin() {
            this.$router.push('/skin').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
        ToWine() {
            this.$router.push('/wine').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
        ToAbout() {
            this.$router.push('/about').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
        ToContact() {
            this.$router.push('/contact').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    // 只有当错误不是 NavigationDuplicated 时才抛出
                    throw err;
                }
            });
        },
    },
}
</script>

<style scoped>
.all {
    height: 82px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    top: 0;
    z-index: 1000;
    background-color: #fff;
}

.test {
    display: flex;
    height: 80px;
    align-items: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    user-select: none;
}

.test:hover {
    color: #e84846;
    border-bottom: 2px solid #e84846;
}

.Header {
    width: 1190px;
    height: 80px;
    display: flex;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    margin: 0, 161.4px;
}

.Headertitle {
    width: 290px;
    height: 48.33px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.msg {
    font-size: 25px;
}

/* .quaryimg:hover {
    filter: brightness(0) saturate(100%) invert(20%) sepia(100%) saturate(7500%) hue-rotate(340deg) brightness(90%) contrast(90%);
} */
/* .button{

    width: 140px;
    height: 40px;
    background-color: #E84846;
    color:#ffffff;
    font-size: 16px;
    border: 0,auto;
    padding: 0 20px;
}
.button-center{
    display: flex;   
    justify-content:center;
    align-items:center;
    

} */
.list {
    width: 488px;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* flex-grow:1; */
}

.list-select {
    width: 488px;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* .select{
    width: 488px;
    height: 80px;
    display: flex;
    justify-content:space-around;
    align-items:center;
}
.quaryimg{
    width: 26px;
    height: 28px;
    border: 0 0 0 10px;
} */
.right {
    width: 710px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    user-select: none;
}

/* .phone{
    padding: 0 10px 0 0;
} */
.change-area {
    display: flex;
    align-items: center;
}

/* .dropdown{
    width: 80.55px;
    height: 40px;
    font-size: 16px;
}
.el-dropdown {
    display: inline-block;
    position: relative;
    color: #666;
    font-size: 16px;
}
.el-dropdown-menu__item, .el-menu-item {
    font-size: 16px;
}
.el-dropdown-menu__item {
    color: #333;
} */
/* .selectmsg{
    width: 80.55px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none; 
}
.input{
    width: 325.45px;
    height: 40px;
    padding: 0 20px;
    border: 0;
    border-left: 1px solid #d8d8d8;
    font-size: 16px;
}
input::placeholder {
    color: #999; 
}

.input:focus {
    outline: none; 
}

.select-button{
    width: 40px;
    height: 40px;
    background-color: #e84846;
    border: 0;
}
.buttonset{
    padding: 0;
    width: 40px;
    height: 40px;
    background-color: #e84846;
    border: 0;
}
.selectimg{
    width: 26px;
    height: 26px;
}
.borderchange{
    height: 40px;
    display: flex;
    justify-content:space-around;
    align-items:center;
    border: 0.6px solid #d8d8d8;
    margin: 0 20px 0 0;
}
.borderchange.focused {
    border: 0.6px solid #e84846;
} */</style>