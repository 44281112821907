<template>
    <div class="all">
        <div class="size">
            <div class="title">
                公司地址
            </div>
            <div class="main">
                <!-- <div class="map">
                </div> -->
                <div class="msg">
                    <div>
                        <div class="menu">
                            公司地址：
                        </div>
                        <div class="site">
                            香港新界上水彩圆道9号深港中心 109-111 室
                        </div>
                    </div>
                    <div>
                        <div class="menu">
                            联系电话:
                        </div>
                        <div class="phone">
                            18823767240
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'ContactPage',
}
</script>

<style scoped>
.all{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0 30px;
}
.size{
    width: 1190px;
    display: flex;
    flex-direction: column;

}
.title{
    color: #333;
    font-size: 30px;
    height: 40px;
    width: 1036px;
    margin: 0 0 30px;
}
/* .map{
    width: 636px;
    height: 295px;
    background-color: gray;

} */
.msg{
    width: 100%;
    height: 247px;
    padding: 24px 0;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.main{
    width: 1036px;
    display: flex;
    background-color: rgb(248, 248, 248);
}
.menu{
    color: #666;
    width: 85px;
    height: 30px;
}
.site{
    width: 100%;
    color: #333;
    line-height: 1.3;
}
.phone{
    color: #333;
    margin: 0 15px 0 0;
    width: 95.46px;
    font-size: 14px;
    font-weight: 600;
}
</style>