<template>
    <div class="SlideShow">
        <div class="text">
            <div class="text1">
                酒产品
            </div>
            <div class="text2">
                Wine products
            </div>
            <div class="list" >
                <div class="item" v-for="item in imgArr" :key="item.id" @click="ToWineItem(item.id,item.url,item.textmsg)">
                    <div class="image-container">
                        <img  class="imgset"   :src="item.url" alt="暂无图片">
                    </div>
                    <div class="imgtextset">
                        <div class="imgtextsize">
                            {{ item.textmsg }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div >
            <el-button class="button" plain @click="ToWine">
                <div class="buttontext" >
                    查看更多
                </div>
            </el-button>
        </div>

    </div>
</template>

<script>
export default {
    name:'WineProducts',
    data(){
        return{
            imgArr:[
                {
                    id:0,
                    url:require("@/assets/WineProducts/WineProducts1.jpg"),
                    textmsg:"名士千邑百兰地"
                },
                {
                    id:1,
                    url:require("@/assets/WineProducts/WineProducts2.jpg"),
                    textmsg:"XO千邑白兰地"
                },
                {
                    id:2,
                    url:require("@/assets/WineProducts/WineProducts3.jpg"),
                    textmsg:"蓝带千邑白兰地"
                },
                {
                    id:3,
                    url:require("@/assets/WineProducts/WineProducts4.jpg"),
                    textmsg:"轩尼诗XO酒"
                },
            ]
        }
    },
    methods: {
        ToWine() {
            this.$router.push('/wine').catch(err => {
            if (err.name !== 'NavigationDuplicated') {
                // 只有当错误不是 NavigationDuplicated 时才抛出
                throw err;
            }
            });
        },
        ToWineItem(id,url,textmsg) {
            this.$router.push({
            path:'/wineitem' ,
            query:{
                id,
                url,
                textmsg,

            }
            }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
                // 只有当错误不是 NavigationDuplicated 时才抛出
                throw err;
            }
            });
        },
    },
}
</script>

<style scoped>
.SlideShow{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px;
}
.text{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text1{
    margin: 30px 0 0;
    font-size: 30px;
}
.text2{
    margin: 15px 0 30px;
    color: #999999;
}
.image-container {
    overflow: hidden; /* 隐藏超出部分 */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 285.5px; /* 图片原始宽度 */
    height: 213.71px; /* 图片原始高度 */
}
.imgset{
    width: 285.5px;
    height: 213.71px;
    margin: 0 8px;
    transition: transform 0.3s ease; /* 添加平滑过渡效果 */
}
.imgset:hover {
    transform: scale(1.15); /* 鼠标悬停时放大图片 */
}
.imgtextset{
    font-size: 18px;
    color: #333333;
    width: 285.5px;
    height: 61.6px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.imgtextsize{
    user-select:none;
    height: 24px;
}
.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden; /* 添加此行以隐藏超出部分 */
    /* 可能需要调整尺寸或内边距 */


}
.item:hover{
    cursor: pointer;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    overflow: hidden;
}
.list{
    display: flex;
    flex-direction: row;
    user-select: none;
    
}
.button{
    width: 490px;
    height: 60px;
    padding: 0 20px;
    margin: 40px 0 0;
}
.button:hover {
  background-color: red; /* 按钮背景变为红色 */
}
.buttontext{
    color: #666666;
    font-size: 20px;
}
.button:hover .buttontext {
  color: white; /* 文本变为白色 */
}
</style>